import React from "react";
import OwlCarousel from "react-owl-carousel";

export default function BrandCarousel({ hasBg }) {
  const options = {
    autoplay: true,
    loop: true,
    margin: 15,
    dots: true,
    slidetransition: "linear",
    autoplayTimeout: 4500,
    autoplayHoverPause: true,
    autoplaySpeed: 4500,
    responsive: {
      0: {
        items: 2,
      },
      500: {
        items: 3,
      },
      600: {
        items: 4,
      },
      800: {
        items: 5,
      },
      1200: {
        items: 6,
      },
    },
  };

  return (
    <>
      <section
        className={`client-section pt-20 pb-30 ${hasBg ? "gray-light-bg" : ""}`}
      >
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-md-8">
              <div className="section-heading text-center mb-4">
                {hasBg ? (
                  <span className="text-uppercase color-secondary sub-title">
                    Strategic Technology Partner
                  </span>
                ) : (
                  ""
                )}
                <h2>Trusted by Global Industry Leaders</h2>
                <p className="lead mb-0">
                  Empowering digital transformation for Fortune 500 companies and category leaders worldwide
                </p>
              </div>
            </div>
          </div>
          <div className="row align-items-center">
            <div className="col-md-12">
              <OwlCarousel
                className="owl-carousel owl-theme clients-carousel dot-indicator"
                {...options}
              >
                <div className="item single-client">
                  <img
                    src="assets/img/client-equinix.png"
                    alt="Equinix"
                    className="client-img"
                  />
                </div>
                <div className="item single-client">
                  <img
                    src="assets/img/client-citi.svg"
                    alt="Citibank"
                    className="client-img"
                  />
                </div>
                <div className="item single-client">
                  <img
                    src="assets/img/client-tesco.png"
                    alt="Tesco"
                    className="client-img"
                  />
                </div>
                <div className="item single-client">
                  <img
                    src="assets/img/client-solarwinds.png"
                    alt="SolarWinds"
                    className="client-img"
                  />
                </div>
                <div className="item single-client">
                  <img
                    src="assets/img/client-dunnhumby.jpeg"
                    alt="dunnhumby"
                    className="client-img"
                  />
                </div>
                <div className="item single-client">
                  <img
                    src="assets/img/client-ctm.svg"
                    alt="Compare The Market"
                    className="client-img"
                  />
                </div>
                <div className="item single-client">
                  <img
                    src="assets/img/client-instinct.png"
                    alt="Instinct"
                    className="client-img"
                  />
                </div>
                <div className="item single-client">
                  <img
                    src="assets/img/client-withu.png"
                    alt="WithU"
                    className="client-img"
                  />
                </div>
                <div className="item single-client">
                  <img
                    src="assets/img/client-firemind.png"
                    alt="Firemind"
                    className="client-img"
                  />
                </div>
                
                <div className="item single-client">
                  <img
                    src="assets/img/client-equinix.png"
                    alt="Equinix"
                    className="client-img"
                  />
                </div>
                <div className="item single-client">
                  <img
                    src="assets/img/client-citi.svg"
                    alt="Citibank"
                    className="client-img"
                  />
                </div>
                <div className="item single-client">
                  <img
                    src="assets/img/client-withu.png"
                    alt="WithU"
                    className="client-img"
                  />
                </div>
              </OwlCarousel>
            </div>
          </div>
          
          <div className="row">
            <div className="col-12 text-center mt-3">
              <a href="#case-studies" className="btn outline-btn">View Success Stories</a>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}