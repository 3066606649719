import React, { useState } from "react";
import axios from "axios";
import { toast } from 'react-toastify';

const Contact = ({ bgColor }) => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
    company: "",
    message: ""
  });

  const [loading, setLoading] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (loading) return;
    setLoading(true);

    const formDataToSend = new FormData();
    
    Object.entries(formData).forEach(([key, value]) => {
      formDataToSend.append(key, value);
    });

    axios
      .post(
        "https://getform.io/f/zbxdlzjb",
        formDataToSend,
        { headers: { Accept: "application/json" } }
      )
      .then(function (response) {
        toast.success('Request sent, we will be in touch!', {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
            }) 
        setLoading(false);
        setFormData({
          name: "",
          email: "",
          phone: "",
          company: "",
          message: ""
        });
      })
      .catch(function (error) {
        toast.error(error.message, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
        });
        setLoading(false);
      });
  };

  return (
    <>
      <section
        id="contact"
        className={`contact-us ptb-100 ${bgColor ? "gray-light-bg" : ""}`}
      >
        <div className="container">
          <div className="row">
            <div className="col-12 pb-3 message-box d-none">
              <div className="alert alert-danger"></div>
            </div>
            <div className="col-md-5">
              <div className="section-heading">
                <h2>Start Your Cloud Transformation</h2>
                <p>
                  Schedule a consultation with our cloud experts to discuss your business needs 
                  and discover how we can accelerate your digital journey.
                </p>
              </div>

              <div className="footer-address">
                <h6 style={{ fontSize: "1.1rem", fontWeight: "bold", marginBottom: "10px" }}>
                  United Kingdom Office
                </h6>
                <p>
                  Purleigh Avenue, Woodford Green<br />
                  <b>London</b>, United Kingdom
                </p>
                <ul>
                  <li>
                    <span><b>Phone:</b> +44 744 899 0683</span>
                  </li>
                </ul>
              </div>

              <div className="footer-address" style={{ marginTop: "25px" }}>
                <h6 style={{ fontSize: "1.1rem", fontWeight: "bold", marginBottom: "10px" }}>
                  Nigeria Office
                </h6>
                <p>
                  Off Awolowo Road<br />
                  <b>Ikoyi, Lagos</b>, Nigeria
                </p>
                <ul>
                  <li>
                    <span><b>Phone:</b> +234 810 159 5909</span>
                  </li>
                </ul>
              </div>

              <div className="footer-address" style={{ marginTop: "25px" }}>
                <ul>
                  <li>
                    <span>
                      <b>Email:</b>&nbsp;
                      <a href="mailto:info@kloud-genius.com" style={{ color: "#3366cc" }}>
                        info@kloud-genius.com
                      </a>
                    </span>
                  </li>
                  <li>
                    <span><b>Support:</b> 24/7 for enterprise clients</span>
                  </li>
                </ul>
              </div>
            </div>

            <div className="col-md-7">
              <form
                onSubmit={handleSubmit}
                className="contact-us-form"
              >
                <h5>Tell us about your project</h5>
                <div className="row">
                  <div className="col-sm-6 col-12">
                    <div className="form-group">
                      <input
                        type="text"
                        className="form-control"
                        name="name"
                        value={formData.name}
                        onChange={handleChange}
                        placeholder="Your name"
                        required
                      />
                    </div>
                  </div>
                  <div className="col-sm-6 col-12">
                    <div className="form-group">
                      <input
                        type="email"
                        className="form-control"
                        name="email"
                        value={formData.email}
                        onChange={handleChange}
                        placeholder="Your email"
                        required
                      />
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-sm-6 col-12">
                    <div className="form-group">
                      <input
                        type="text"
                        name="phone"
                        value={formData.phone}
                        onChange={handleChange}
                        className="form-control"
                        placeholder="Your phone number"
                      />
                    </div>
                  </div>
                  <div className="col-sm-6 col-12">
                    <div className="form-group">
                      <input
                        type="text"
                        name="company"
                        value={formData.company}
                        onChange={handleChange}
                        className="form-control"
                        placeholder="Your company"
                      />
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-12">
                    <div className="form-group">
                      <textarea
                        name="message"
                        value={formData.message}
                        onChange={handleChange}
                        className="form-control"
                        rows="7"
                        placeholder="Tell us about your cloud requirements"
                      ></textarea>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-sm-12 mt-3">
                    <button
                      type="submit"
                      className="btn solid-btn"
                      disabled={loading}
                    >
                      {loading ? "Sending..." : "Schedule Consultation"}
                    </button>
                  </div>
                </div>
              </form>
              <p className="form-message"></p>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Contact;