export default function Price({ hasBg }) {
  return (
    <>
      <div className="overflow-hidden">
        <section
          id="pricing"
          className={`package-section ${
            hasBg ? "gray-light-bg" : "background-shape-right"
          } ptb-100`}
        >
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-md-8">
                <div className="section-heading text-center mb-5">
                  <h2>Cloud Investment Plans</h2>
                  <p className="lead">
                    Tailored solutions with flexible engagement models to match your business scale and objectives.
                  </p>
                  <div className="support-badge mt-4 mb-4">
                    <div className="bg-primary text-white p-2 rounded fw-bold text-center" style={{ width: "50%", margin: "0 auto", fontSize: "0.9rem" }}>
                      All plans include 6 months of dedicated support
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row justify-content-center">
              <div className="col-lg-4 col-md">
                <div className="card text-center single-pricing-pack p-5">
                  <h5 className="mb-1">Startup Accelerator</h5>
                  <p className="text-muted small mb-3">Ideal for seed to Series A startups</p>
                  
                  <div className="pricing-img mt-2 mb-4">
                    <img
                      src="assets/img/basic.svg"
                      alt="Startup plan"
                      className="img-fluid"
                    />
                  </div>

                  <div className="card-body p-0">
                    <ul className="list-unstyled text-sm pricing-feature-list">
                      <li><strong>Cloud Architecture Blueprint</strong> - Scalable foundation design</li>
                      <li><strong>FinOps Strategy</strong> - 30% average cost reduction</li>
                      <li>Infrastructure as Code Implementation</li>
                      <li>Kubernetes Deployment & Training</li>
                      <li>CI/CD Pipeline Automation</li>
                      <li>Security Posture Assessment</li>
                      <li>Compliance Framework Setup</li>
                      <li>Tech Stack Modernization Roadmap</li>
                    </ul>
                    <div className="py-4 border-0 pricing-header">
                      <div className="h1 text-center mb-0 color-secondary">
                        $<span className="price font-weight-bolder">20,000</span>
                      </div>
                    </div>
                    <a href="#contact" className="btn outline-btn">
                      Schedule Consultation
                    </a>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md">
                <div className="card text-center popular-price single-pricing-pack p-5">
                  <span className="badge bg-primary text-white position-absolute top-0 start-50 translate-middle mt-3">
                    POPULAR
                  </span>
                  <h5 className="mb-1">Mid-Market Growth</h5>
                  <p className="text-muted small mb-3">For established businesses scaling cloud operations</p>
                  
                  <div className="pricing-img mt-2 mb-4">
                    <img
                      src="assets/img/standard.svg"
                      alt="Mid-market plan"
                      className="img-fluid"
                    />
                  </div>

                  <div className="card-body p-0">
                    <ul className="list-unstyled text-sm pricing-feature-list">
                      <li><strong>Multi-Cloud Architecture Optimization</strong></li>
                      <li><strong>FinOps Implementation</strong> - 40% average savings</li>
                      <li><strong>Unified Observability Platform</strong></li>
                      <li>DevOps Maturity Acceleration</li>
                      <li>Zero Trust Security Implementation</li>
                      <li>Regulatory Compliance Automation</li>
                      <li>Technical Documentation System</li>
                    </ul>
                    <div className="py-4 border-0 pricing-header">
                      <div className="h1 text-center mb-0 color-secondary">
                        $<span className="price font-weight-bolder">50,000</span>
                      </div>
                    </div>
                    <a href="#contact" className="btn solid-btn">
                      Purchase Now
                    </a>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md">
                <div className="card text-center single-pricing-pack p-5">
                  <h5 className="mb-1">Enterprise Solution</h5>
                  <p className="text-muted small mb-3">For Fortune 500 and large organizations</p>
                  
                  <div className="pricing-img mt-2 mb-4">
                    <img
                      src="assets/img/unlimited.svg"
                      alt="Enterprise plan"
                      className="img-fluid"
                    />
                  </div>

                  <div className="card-body p-0">
                    <ul className="list-unstyled text-sm pricing-feature-list">
                      <li><strong>Advanced Observability & AIOps</strong></li>
                      <li><strong>GitOps & Platform Engineering</strong></li>
                      <li><strong>Service Mesh Architecture</strong></li>
                      <li>Self-Hosted CI/CD Infrastructure</li>
                      <li>SRE Reliability Implementation</li>
                      <li>Data Architecture Optimization</li>
                      <li>Custom SLA & 24/7 Support</li>
                    </ul>
                    <div className="py-4 border-0 pricing-header">
                      <div className="h1 text-center mb-0 color-secondary">
                        $<span className="price font-weight-bolder">100,000</span>
                      </div>
                    </div>
                    <a href="#contact" className="btn outline-btn">
                      Contact Sales
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div className="mt-5 text-center">
              <p className="mb-2">
                Need a custom solution?
                <a href="#contact" className="color-secondary">
                  {" "}
                  Contact our cloud experts{" "}
                </a>
                for a personalized assessment of your infrastructure needs.
              </p>
            </div>
          </div>
        </section>
      </div>
    </>
  );
}