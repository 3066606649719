import React from "react";

export default function FeatureImgThree() {
  return (
    <>
      <section id="features" className="feature-section ptb-100">
        <div className="container">
          <div className="row align-items-center justify-content-between">
            <div className="col-md-4">
              <div className="download-img">
                <img
                  src="assets/img/image-10.jpeg"
                  alt="Cloud Engineering Services"
                  className="img-fluid"
                />
              </div>
            </div>
            <div className="col-md-7">
              <div className="feature-contents section-heading">
                <h2>Cloud Engineering Services</h2>
                <p>
                Our cloud engineering framework delivers enterprise-grade solutions that optimize costs, enhance security, and accelerate innovation through containerized architectures and infrastructure automation.
                </p>

                <div className="feature-content-wrap">
                  <ul className="nav nav-tabs feature-tab" data-tabs="tabs">
                    <li className="nav-item">
                      <a
                        className="nav-link active h6"
                        href="#tab6-3"
                        data-toggle="tab"
                      >
                        <span className="ti-crown"></span>
                        Architecture
                      </a>
                    </li>
                    <li className="nav-item">
                      <a
                        className="nav-link h6"
                        href="#tab6-2"
                        data-toggle="tab"
                      >
                        <span className="ti-server"></span>
                        Infrastructure
                      </a>
                    </li>
                    <li className="nav-item">
                      <a
                        className="nav-link h6"
                        href="#tab6-1"
                        data-toggle="tab"
                      >
                        <span className="ti-eye"></span>
                        Observability
                      </a>
                    </li>
                    <li className="nav-item">
                      <a
                        className="nav-link h6"
                        href="#tab6-4"
                        data-toggle="tab"
                      >
                        <span className="ti-rocket"></span>
                        Developer Experience
                      </a>
                    </li>
                  </ul>
                  <div className="tab-content feature-tab-content">
                    <div className="tab-pane active" id="tab6-3">
                      <div className="row">
                        <div className="col single-feature mb-4">
                          <div className="d-flex align-items-center mb-2">
                            <span className="ti-layout-grid2 rounded mr-3 icon icon-color-2"></span>
                            <h5 className="mb-0">Microservices Architecture</h5>
                          </div>
                          <p>
                            Scalable domain-driven design patterns with service boundaries 
                            aligned to business capabilities, enabling independent scaling 
                            and deployment of components.
                          </p>
                        </div>
                        <div className="col single-feature mb-4">
                          <div className="d-flex align-items-center mb-2">
                            <span className="ti-server rounded mr-3 icon icon-color-3"></span>
                            <h5 className="mb-0">Serverless Design</h5>
                          </div>
                          <p>
                            Event-driven architectures leveraging managed services to 
                            reduce operational complexity while improving scalability 
                            and cost efficiency for variable workloads.
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="tab-pane" id="tab6-2">
                      <div className="single-feature">
                        <div className="d-flex align-items-center mb-2">
                          <span className="ti-cloud rounded mr-3 icon icon-color-1"></span>
                          <h5 className="mb-0">Multi-Cloud Solutions</h5>
                        </div>
                        <p>
                          Custom architectures across AWS, Azure, and GCP optimized for 
                          performance, cost, and reliability with up to 40% cost reduction.
                        </p>
                      </div>
                      <div className="single-feature mb-4">
                        <div className="d-flex align-items-center mb-2">
                          <span className="ti-code rounded mr-3 icon icon-color-2"></span>
                          <h5 className="mb-0">Infrastructure as Code</h5>
                        </div>
                        <p>
                          Enterprise-grade Terraform, CloudFormation, and Pulumi implementations 
                          with modular designs, versioning, and automated validation.
                        </p>
                      </div>
                    </div>
                    <div className="tab-pane" id="tab6-1">
                      <ul className="list-unstyled">
                        <li className="py-2">
                          <div className="d-flex align-items-center">
                            <div>
                              <div className="badge badge-circle badge-primary mr-3">
                                <span className="ti-check"></span>
                              </div>
                            </div>
                            <div>
                              <p className="mb-0">Unified Logging & Centralized Log Analytics</p>
                            </div>
                          </div>
                        </li>
                        <li className="py-2">
                          <div className="d-flex align-items-center">
                            <div>
                              <div className="badge badge-circle badge-primary mr-3">
                                <span className="ti-check"></span>
                              </div>
                            </div>
                            <div>
                              <p className="mb-0">
                                Real-time Metrics Monitoring with Custom Dashboards
                              </p>
                            </div>
                          </div>
                        </li>
                        <li className="py-2">
                          <div className="d-flex align-items-center">
                            <div>
                              <div className="badge badge-circle badge-primary mr-3">
                                <span className="ti-check"></span>
                              </div>
                            </div>
                            <div>
                              <p className="mb-0">
                                Distributed Tracing for Microservices Architectures
                              </p>
                            </div>
                          </div>
                        </li>
                        <li className="py-2">
                          <div className="d-flex align-items-center">
                            <div>
                              <div className="badge badge-circle badge-primary mr-3">
                                <span className="ti-check"></span>
                              </div>
                            </div>
                            <div>
                              <p className="mb-0">
                                Continuous Profiling for Performance Optimization
                              </p>
                            </div>
                          </div>
                        </li>
                        <li className="py-2">
                          <div className="d-flex align-items-center">
                            <div>
                              <div className="badge badge-circle badge-primary mr-3">
                                <span className="ti-check"></span>
                              </div>
                            </div>
                            <div>
                              <p className="mb-0">
                                AIOps for Intelligent Anomaly Detection & Alerting
                              </p>
                            </div>
                          </div>
                        </li>
                      </ul>
                    </div>
                    <div className="tab-pane" id="tab6-4">
                      <p>
                        We enhance developer productivity through self-service internal platforms, 
                        GitOps workflows, comprehensive CI/CD pipelines, and infrastructure automation. 
                        Our approach delivers 50-70% faster deployment cycles and reduces operational 
                        overhead while maintaining governance and security controls.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}